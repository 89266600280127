@import '../../../styles/base/colors.scss';

.forgot_account_wrapper {
    max-width: 258px;
    padding: 30px;
    background-color: $white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: $prussian_blue;
        margin-bottom: 10px;
    }
    .forgot_account_subtitle {
        font-size: 15px;
        line-height: 18px;
        color: $prussian_blue;
        margin-bottom: 16px;
        text-align: center;
    }
    form {
        margin-bottom: 9px;
        width: 100%;
        :global(.MuiFormControl-root) {
            width: 100%;
        }
    }
    .back_link {
        margin-top: 30px;
        font-size: 15px;
        font-weight: bold;
        line-height: 18px;
        color: $pelorous;
    }
}