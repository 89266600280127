@import '../../styles/base/colors.scss';
    .sensor_wrapper {
        position: relative;
    }
    .cards_wrapper_all_cards {
        display: flex;
        background-color: $white;
        width: 914px;
        border-radius: 15px;

        .form_section {
            padding: 20px 0px;
            border-left: 3px solid $pattens_blue;
            .credit_card_error_msg {
                font-size: 12px;
                line-height: 15px;
                color: $persian_red;
                padding-left: 30px;
            }
        }

        .billing_details_button_section {
            display: flex;
            width: 500px;
            justify-content: flex-end;
            margin-bottom: 10px;
            .start_free_trial_btn {
                width: 211px;
            }
        }
    }
    

    .sensor_wrapper_mobile {
        position: relative;
        .cards_wrapper_all_cards {
            display: block;
            width: auto;
        }
        .billing_details_button_section {
            display: block;
            padding: 0px 40px;
        }
        .form_section {
            border-left: 0px; 
        }
    }

