@import '../../../styles/base/colors.scss';

.email_activation_wrapper {
    max-width: 258px;
    padding: 30px;
    background-color: $white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: $prussian_blue;
        margin-bottom: 10px;
        text-align: center;
    }
    p {
        font-size: 15px;
        line-height: 18px;
        color: $prussian_blue;
        margin-bottom: 16px;
        text-align: center;
    }
}