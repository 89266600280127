@import '../../../styles/base/colors.scss';

.login_wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: $white;
    border-radius: 15px;
    max-width: 260px;
    form {
        display: flex;
        align-items: center;
        flex-direction: column;
            :global(.MuiInputBase-root) {
               width: 260px;
            }
        a {
            color: $pelorous;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 20px;
        }
    }
    .login_title {
        color: $prussian_blue;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding-bottom: 25px;
    }
    :global(.MuiFormControl-root) {
        margin-top: 10px;
        &:first-of-type {
            margin-top: 0px;
        }
    }
    .login_existing_account {
        text-align: center;
    }
}