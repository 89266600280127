@import '../../../styles/base/colors.scss';

.layout_wrapper {
    .create_account_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background-color: $white;
        padding: 30px;
        max-width: 260px;
    }
    form {
        display: flex;
        flex-direction: column;
        :global(.MuiFormControl-root) {
            margin-bottom: 10px;
        }
        :global(.MuiInputBase-root) {
            width: 260px;
        }
        .created_account_invalid_email {
            border: 1px solid $persian_red;
            border-radius: 9px;
            margin-bottom: 10px;
            :global(.MuiFormControl-root) {
              margin-bottom: 0px;
            }
        }
    }

    h3 {
        text-align: center;
        font-size: 18px;
        color: $prussian_blue;
        padding-bottom: 25px;
    }
}
