@import '../../../styles/base/colors.scss';

.register_sensor_desktop_wrapper {
    margin: 10px;
}
.register_sensor_mobile_wrapper {
    margin: 0px;
}
.register_sensor_wrapper {
    width: 288px;
    border-radius: 9px;
    box-shadow: 0px 3px 6px rgba($black, 0.16);
    background-color: $alice_blue;
    flex: 0 1 288px;
    margin-bottom: 20px;
    .register_sensor_header {
        background-color: $white;
        box-shadow: 0px 3px 6px rgba($black, 0.05);
        border-radius: 9px 9px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        height: 168px;
        justify-content: center;
        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            color: $prussian_blue;
            margin-top: 17px;
        }
        .plan {
            font-size: 13px;
            line-height: 16px;
            color: $prussian_blue;
            margin-top: 4px;
            white-space: nowrap;
        }
        .exclamation_wrapper,
        .sensor_icon_wrapper,
        .pending_cancelation_wrapper,
        .cancelled_wrapper {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .sensor_icon_wrapper {
            background-color: $pigment_green;
        }
        .exclamation_wrapper {
            background-color: $persian_red;
        }
        .cancelled_wrapper {
            background-color: $bali_hai;
        }
        .pending_cancelation_wrapper {
            background-color: $malibu;
        }
        :global(.icon_svg) {
            background-color: $white;
        }
    }
    .register_sensor_image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 188px;
        img {
            max-width: 228px;
        }
    }
    .register_sensor_button_section,
    .register_sensor_review {
        box-shadow: 0px -3px 6px rgba($black, 0.05);
        background-color: $white;
        display: flex;
        border-radius: 0px 0px 9px 9px;
    }
    .register_sensor_button_section {
        height: 100px;
        button {
            margin: 30px;
        }
    }

    .register_sensor_review {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        line-height: 18px;
        color: $prussian_blue;
        padding: 30px;
        min-height: 138px;
        box-sizing: border-box;
        .sensor_name {
            font-weight: bold;
        }
    }
    .register_sensor_review_expired {
        padding: 38px 0px;
    }
    .register_sensor_review_blocked {
        padding: 13px 0px;
    }
    .start_new_button_section,
    .fix_billing_issue_button_section {
        width: 228px;
    }
    .start_new_button_section {
        margin-top: 20px;
    }
    .fix_billing_issue_button_section {
        margin-top: 10px;
    }
    .registration_plan_link {
        cursor: pointer;
        color: $white;
        background-color: $pelorous;
        margin-top: 20px;
        padding: 11px 0px;
        border-radius: 9px;
        font-weight: bold;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }
}