@import '../../../styles/base/colors.scss';

.billing_mobile_details_wrapper {
    .billing_details_state_zip_section {
        flex-direction: column;
    }
    .billing_details_state,
    .billing_details_state_zip_section,
    .billing_details_address,
    .billing_details_city {
        margin-bottom: 7px;
    }
    :global(.MuiFormControl-root) {
        width: 100%;
    }
    .country_select {
        width: 100%;
    }
}

.billing_details_wrapper {
    .billing_details_address, .billing_details_city {
        margin-bottom: 10px;
        :global(.MuiInputBase-root) {
            width: 470px;
        }
    }
    .billing_details_state_zip_section {
        margin-bottom: 10px;
    }
    .billing_details_state {
        margin-right: 10px;
    }
    .billing_details_state, .billing_details_zip_code {
        :global(.MuiInputBase-root) {
            width: 230px;
        }
    }
    .country_select {
        width: 230px;
    }
}

.billing_mobile_details_wrapper,
.billing_details_wrapper {
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    padding: 20px 30px;
    background: $white;
    border-radius: 0px ​0px 15px 0px;
    text-align: left;
    .billing_details_title {
        font-size: 18px;
        color: $prussian_blue;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .billing_details_input_title {
        font-size: 14px;
        color: rgba($fiord, 0.8);
        margin-bottom: 5px;
    }
    .billing_details_state_zip_section {
        display: flex;
        .billing_details_state, .billing_details_zip_code {
            :global(.MuiInputBase-root) {
                height: 30px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }
        }
    }
    .billing_details_address, .billing_details_city {
        :global(.MuiInputBase-root) {
            height: 30px;
            border-radius: 3px;
            input {
                text-align: left;
                text-transform: capitalize;
                padding-left: 14px;
                padding-right: 14px;
                line-height: 18px;
            }
        }
    }

    .country_select {
        height: 30px;
        color: $prussian_blue;
        font-size: 15px;
        line-height: 18px;
        background-color: $alice_blue;
        border-radius: 3px;
        &:after, &:before {
            content: none;
        }
        :global(.MuiSelect-select) {
            padding-left: 14px;
            background-color: transparent;
        }
    }

}

.billing_details_wrapper_mobile {
    padding: 20px 40px;
    width: fit-content;
    .billing_details_state_zip_section {
        display: block;
        margin-bottom: 10px;
        .billing_details_state, .billing_details_zip_code {
            margin-bottom: 10px;
            :global(.MuiInputBase-root) {
                width: 280px;
                height: 30px;
                margin-right: 10px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding: 0px 14px;
                    line-height: 18px;
                }
            }
        }
    }
    .billing_details_address, .billing_details_city {
        margin-bottom: 10px;
        :global(.MuiInputBase-root) {
            width: 280px;
            height: 30px;
            margin-right: 10px;
            border-radius: 3px;
            input {
                text-align: left;
                text-transform: capitalize;
                padding: 0px 14px;
                line-height: 18px;
            }
        }
    }
}

// styles for dropdown list
:global(.MuiListItem-root.MuiListItem-button) {
    color: $prussian_blue;
    font-size: 15px;
    line-height: 18px;
}