@import '../../../styles/base/colors.scss';

.home_measure_wraper {
    background-color: rgba($pattens_blue, 0.5);
    padding: 68px 120px 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .home_image_section,
    .home_measure_content {
        width: 50%;
    }

    .home_image_section {
        margin-right: 92px;
        img {
            width: 100%;
            height: auto;
        }
    }
}

.home_measure_wrapper_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .desc_area_container {
        margin: 0px 20px;
    }
    .heath_image, .putt_image {
        width: 100%;
    }
    .heath_image_container {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        .heath_image {
            margin-top: 50px;
        }
    }
    .home_measure_image {
        background-color: rgba($pattens_blue, 0.5);
        padding: 0px 20px 30px;
        width: 100%;
        box-sizing: border-box;
        .putt_image {
            margin-top: -20%;
        }
    }
}