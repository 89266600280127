@import '../../../styles/base/colors.scss';

.account_card_wrapper {
    max-width: 255px;
}
.account_mobile_card_wrapper,
.account_card_wrapper {
    padding: 30px;
    h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: $prussian_blue;
        text-align: center;
        margin-bottom: 22px;
    }
    form {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
    }
    .field_wrapper {
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        label {
            font-size: 14px;
            line-height: 18px;
            color: rgba($fiord, 0.8);
            margin-bottom: 2px;
        }
    }
    .link_section {
        display: flex;
        justify-content: flex-end;
        div {
            color: $pelorous;
            font-size: 18px;
            line-height: 22px;
            cursor: pointer;
            &.bolded {
                margin-left: 20px;
                font-weight: bold;
            }
            &.hidden {
                display: none;
            }
        }
    }
    .button_mobile_section {
        button {
            &:first-of-type {
                margin-bottom: 10px;
            }
            &.hidden {
                display: none;
            }  
        }
    }
}