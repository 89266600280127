@import '../../../styles/base/colors.scss';

.existing_account_wrapper {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 30px;
    p {
        color: $prussian_blue;
        margin-bottom: 6px;
    }
    a {
        font-weight: bold;
        color: $pelorous;
    }
}