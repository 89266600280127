@import '../../../styles/base/colors.scss';

.payment_sensor_wrapper {
    &.hidden {
        display: none;
    }
}
.payment_card_wrapper {
    .payment_card_elements {
        width: 300px;
        padding: 20px 30px;
        position: relative;
        .payment_sensor_img {
            width: 38px;
            height: 22px;
            padding-bottom: 7px;
        }
        .payment_title {
            font-size: 18px;
            color: $prussian_blue;
            font-weight: bold;
            margin-bottom: 13px;
        }
        .payment_price {
            font-size: 22px;
            color: $prussian_blue;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .payment_subtitle {
            font-size: 14px;
            color: rgba($fiord, 0.8);
            margin-bottom: 30px;
        }
        .payment_description {
            font-size: 14px;
            color: $prussian_blue;
            margin-bottom: 10px;
        }
        .payment_button {
            margin: 26px 0px 10px 0px;
        }
        .check_solid_section {
            display: flex;
            &:first-of-type {
                margin-top: 30px;
            }
            .check_solid {
                width: 13px;
                height: 10px;
                padding: 3px 10px 0px 0px;
            }
        }

        .payment_switch_btn_section {
            height: 40px;
            background-color: $malibu;
            border-radius: 25px;
            align-items: center;
            display: flex;
            margin-bottom: 20px;
            }
            .payment_button_switch {
                background-color: $malibu;
                color: $white;
                border-radius: 20px;
                font-size: 15px;
                width: 179px;
                height: 32px;
                display: flex;
                align-items: center;
                margin: 4px;
                justify-content: center;
                padding: 0px;

                &.active {
                    background-color: $white;
                    color: $pelorous;
                }
            }
        }

    .order_total_section {
        padding: 20px 0px;
        margin-top: 25px;
        .total_today {
            font-size: 14px;
            color: $prussian_blue;
            margin-bottom: 9px;
        }
        .order_total {
            font-size: 14px;
            color: $prussian_blue;
        }
    }
    .payment_sensor_total {
        &:before {
            content: '';
            position: absolute;
            height: 3px;
            width: 100%;
            background-color: $pattens_blue;
            left: 0;
        }
    }
}
