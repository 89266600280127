$alice_blue: #E9EEF1;
$bali_hai: #839CB1;
$black: #000;
$fiord: #4D555B;
$malibu: #6BAED6;
$medium_aquamarine: #7ACFA8;
$pattens_blue: #DCEBF6;
$pelorous: #1E87C9;
$persian_red: #DB2D26;
$pigment_green: #17B45A;
$pomegranate: #F44336;
$prussian_blue: #083A65;
$summer_sky: #26B0D6;
$white: #FFF;
$wild_blue_yonder: #7C88B8;