@import '../../../styles/base/colors.scss';

.payment_mobile_details_wrapper {
    .payment_details_credit_card_numbers_section{
        display: flex;
        flex-direction: column;
        .payment_details_credit_card_number,
        .payment_details_credit_security_code,
        .payment_details_credit_expiration_month, 
        .payment_details_credit_expiration_year {
            margin-bottom: 7px;
        }
    }
    :global(.MuiFormControl-root) {
        width: 100%;
    }
}

.payment_details_wrapper {
    .payment_details_credit_card_name {
        margin-bottom: 7px;
        :global(.MuiInputBase-root) {
            width: 470px;
        }
    }     
    .payment_details_credit_card_number {
        width: 230px;
        margin-right: 10px;
    }
}

.payment_details_expiration_section {
    display: flex;
    .payment_details_credit_expiration_month {
        margin-right: 10px;
    }
}

.payment_mobile_details_wrapper,
.payment_details_wrapper {
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    padding: 6px 30px;
    background: $white;
    border-radius: 0px 15px 0px 0px;
    text-align: left;
    .payment_details_title {
        font-size: 18px;
        color: $prussian_blue;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .payment_details_date_input_title,
    .payment_details_input_title {
        font-size: 14px;
        color: rgba($fiord, 0.8);
        margin-bottom: 5px;
    }
    .payment_details_date_input_title {
        margin-left: -6px;
    }
    .payment_details_credit_card_name {
        margin-bottom: 7px;
            :global(.MuiInputBase-root) {
                height: 30px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }
    
    }
    .payment_details_credit_card_numbers_section{
        display: flex;
        .payment_details_credit_card_number {
            :global(.MuiInputBase-root) {
                height: 30px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }
        }
        .payment_details_credit_security_code {
            width: 90px;
            margin-right: 10px;
        }
        .payment_details_credit_expiration_month, .payment_details_credit_expiration_year {
            width: 60px;

        }
    }
}

.payment_details_wrapper_mobile { 
    padding: 6px 40px;
    .payment_details_credit_card_name {
        margin-bottom: 10px;
            :global(.MuiInputBase-root) {
                height: 30px;
                width: 280px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }
    
    }
    .payment_details_credit_card_numbers_section{
        display: block;
        margin-bottom: 10px;
        .payment_details_credit_card_number {
            :global(.MuiInputBase-root) {
                height: 30px;
                width: 280px;
                margin-right: 10px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }
        }
        .payment_details_credit_security_code {
            margin-bottom: 10px;
            :global(.MuiInputBase-root) {
                height: 30px;
                width: 280px;
                margin-right: 10px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }
        }
        .payment_details_credit_expiration_month, .payment_details_credit_expiration_year {
            margin-bottom: 10px;
            :global(.MuiInputBase-root) {
                height: 30px;
                width: 280px;
                margin-right: 10px;
                border-radius: 3px;
                input {
                    text-align: left;
                    text-transform: capitalize;
                    padding-left: 14px;
                    padding-right: 14px;
                    line-height: 18px;
                }
            }

        }
    }
}