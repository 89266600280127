@import '../../../styles/base/colors.scss';

.reset_password_wrapper {
    width: 260px;
    padding: 30px;
    background-color: $white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: $prussian_blue;
        margin-bottom: 10px;
    }
    .reset_password_subheader {
        font-size: 12px;
        line-height: 17px;
        color: $prussian_blue;
        padding-bottom: 10px;
    }
    form {
        margin-bottom: 10px;
        width: 100%;
        :global(.MuiFormControl-root) {
            width: 100%;
        }
    }
}