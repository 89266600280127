@import '../../../styles/base/colors.scss';

.home_main_wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 44px;
    .home_main_content,
    .home_main_image {
        width: 50%;
    }
    .home_main_content {
        padding: 127px 61px 0px 151px;
    }
    .home_apple_download {
        width: 182px;
        height: 62px;
        background-image: url('../../../assets/images/image_apple_download.png');
        background-size: cover;
        border: none;
        cursor: pointer;
        margin-top: 26px;
        outline: none;
    }
    .home_main_image {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            width: 100%;
            height: 80%;
            background-color: rgba($pattens_blue, 0.5);
            border-radius: 0px 0px 0px 150px;
            z-index: 1;
        }
        img {
            position: relative;
            z-index: 2;
            width: 80%;
            padding-left: 40px;
            padding-top: 30px;
        }
    }
}

.home_main_wrapper_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
    .desc_area_container {
        margin: 0px 20px;
    }
    .home_apple_download {
        width: 182px;
        height: 62px;
        background-image: url('../../../assets/images/image_apple_download.png');
        background-size: cover;
        border: none;
        cursor: pointer;
        margin-top: 26px;
        outline: none;
    }
    .home_main_image {
        display: flex;
        flex-direction: column;
        background-color: rgba($pattens_blue, 0.5);
        padding: 20px 20px 30px;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        img {
            width: 100%;
        }
    }
}