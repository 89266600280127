@import '../../../styles/base/colors.scss';

.layout_wrapper {
    .create_password_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background-color: $white;
        padding: 30px;
        max-width: 260px;
    }
    form {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        :global(.MuiInputBase-root) {
            width: 260px;
        }
    }

    .checkbox_section {
        display: flex;
        width: 260px;
        margin-bottom: 20px;
    }

    .my-checkbox-wrapper .MuiCheckbox-colorPrimary {
        color: $pelorous;
    }

    :global(.MuiCheckbox-root) {
        padding: 0px;
    }
    :global(.MuiSvgIcon-root) {
        color: $pelorous;
        border-radius: 5px;
    }
    h3 {
        text-align: center;
        font-size: 18px;
        color: $prussian_blue;
        padding-bottom: 15px;
    }
    .create_password_subheader {
        font-size: 12px;
        line-height: 17px;
        color: $prussian_blue;
        padding-bottom: 10px;
    }
    .terms_of_service {
        text-align: center;
        font-size: 12px;
        color: $prussian_blue;
        margin-bottom: 6px;
        text-align: left;
        padding-right: 70px;
        a {
            font-size: 12px;
        }
    }
}