@import '../../../styles/base/colors.scss';

.plan_details_wrapper {
    a {
        color: $pelorous;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        span {
            background-color: $pelorous;
            margin-right: 8px;
        }
    }
    .plan_section_wrapper {
        padding: 25px 20px;
        background-color: $white;
        max-width: 278px;
        border-radius: 15px;
        h2 {
            font-size: 22px;
            font-weight: bold;
            line-height: 27px;
            color: $prussian_blue;
        }
        .sensor_section {
            display: flex;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 19px;
            p {
                font-size: 15px;
                font-weight: bold;
                line-height: 18px;
                color: $prussian_blue;
            }
            .sensor_status_pending,
            .sensor_status {
                font-size: 14px;
                line-height: 18px;
                margin-left: 10px;
            }
            .sensor_status {
                color: $pigment_green;
            }
            .sensor_status_pending {
                color: $wild_blue_yonder;
            }
        }
        .plan_pending_switch_section {
            .billing_details {
                margin-bottom: 17px;
            }
        }
        .plan_section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 18px;
                font-weight: bold;
                line-height: 22px;
                color: $prussian_blue;
            } 
        }
        .billing_details {
            font-size: 14px;
            line-height: 18px;
            color: rgba($fiord, 0.8);
            margin-top: 5px;
            margin-bottom: 30px;
        }
        .plan_button_section {
            p {
                font-size: 15px;
                font-weight: bold;
                line-height: 18px;
                color: $pelorous;
                margin-top: 19px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}