@import '../../styles/base/colors.scss';

.dialog_wrapper {
    .dialog_title {
        text-transform: uppercase;
    }
    :global(.MuiButton-root) {
        color: $white;
        font-size: 15px;
        font-weight: bold;
        line-height: 18px;
        background-color: $pelorous;
        border: none;
        border-radius: 9px;
        padding: 11px;
        outline: none;
        cursor: pointer;
        &:hover {
            background-color: rgba($pelorous, 0.8);
        }
    }
}
