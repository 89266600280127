@import '../../styles/base/colors.scss';

.loading_wrapper {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    :global(.MuiCircularProgress-root) {
        position: fixed;
        top: 50%;
        left: 50%;
        color: $pelorous;
    }
}