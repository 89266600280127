@import '../../styles/base/colors.scss';

.description_content {
    color: $prussian_blue;
    &.center {
        text-align: center;
    }
    h2 {
        font-size: 40px;
        line-height: 49px;
        font-weight: bold;
        span {
            color: $summer_sky;
        }
    }
    p {
        padding-top: 26px;
        font-size: 25px;
        line-height: 30px;
    }
}