@import '../../styles/base/colors.scss';

.credit_card_field {
    border-radius: 3px;
    height: 30px;
    width: 100%;
    border: none;
    background-color: $alice_blue;
    padding: 0px 14px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: $prussian_blue;
    outline: none;
    &:invalid {
        border: 1px solid $persian_red;
    }
}