@import '../../../styles/base/colors.scss';

.sidebar_item_wrapper {
    padding: 5px 0px 5px 20px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    &:not(.active) {
        color: $pelorous;
        :global(.icon_svg) {
            background-color: rgba($white, 0.5);
        }
        .icon_wrapper {
            background-color: rgba($malibu, 0.4);
        }
    }
    &.active {
        color: $pelorous;
        background: transparent linear-gradient(90deg, rgba($pelorous, 0.203) 0%, rgba($pelorous, 0) 100%);
        position: relative;
        font-weight: bold;
        .icon_wrapper {
            background-color: $pelorous;
        }
        :global(.icon_svg) {
            background-color: $white;
        }
    }
    .icon_wrapper {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar_item {
        font-size: 18px;
        line-height: 22px;
        padding-left: 15px;
    }
}

.sidebar_item_wrapper_mobile {
    padding: 0px;
    margin: 5px 0px;
    flex-direction: column;
    &.active {
        background: transparent;
        .icon_wrapper {
            background-color: $pelorous;
        }
        :global(.icon_svg) {
            background-color: $white;
        }
    }
    .sidebar_item {
        padding: 15px 0px 0px 0px;
    }
}