@import '../../../styles/base/colors.scss';

.new_sensor_wrapper {
    width: 640px;
    background-color: $white;
    border-radius: 9px;
    padding: 30px;
    display: flex;
    text-align: center;
    .inputs_section {
        width: 65%;
        padding-right: 38px;
        .new_sensor_title {
            font-size: 18px;
            color: $prussian_blue;
            text-align: center;
            padding-bottom: 13px;
            margin: 0px;
        }
        .new_sensor_subtitle {
            font-size: 12px;
            color: $prussian_blue;
            text-align: center;
            padding-bottom: 20px;
        }
        .ns_inputs_section {
            text-align: center;
            padding-bottom: 30px;
        }
        button {
            max-width: 260px;
        }
        .error_msg {
            font-size: 12px;
            color: $pomegranate;
            text-align: center;
            padding-top: 10px;
        }
        :global(.MuiInputBase-root) {
            max-width: 50px;
            margin-right: 10px;
        }
        input {
            padding: 0px;
        }
    }
    .new_sensor_picture_section {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 35%;
    }
}

.new_sensor_wrapper_mobile {
    width: auto;
    flex-direction: column;
    .inputs_section {
        width: 100%;
        padding-right: 0px;
        .ns_inputs_section {
            text-align: center;
            padding-bottom: 30px;
        }
        button {
            max-width: 210px;
            margin: 20px 28px 0px 20px;
        }
        :global(.MuiInputBase-root) {
            max-width: 40px;
            margin-right: 5px;
        }
    }
    .new_sensor_picture_section {
        width: 100%;
        padding-left: 10px;
    }
}
.hidden {
    display: none;
}