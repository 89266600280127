@import '../../../styles/base/colors.scss';

.plan_dialog_wrapper {
    .plan_dialog_title h2 {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        color: $prussian_blue;
        text-align: center;
    }
    :global(.MuiDialog-paper) {
        border-radius: 15px;
        max-width: 318px;
    }
    :global(.MuiDialogTitle-root) {
        padding: 30px 30px 10px;
    }
    :global(.MuiDialogContent-root) {
        padding: 0px 30px 20px;
        p{
            font-size: 14px;
            line-height: 18px;
            color: $prussian_blue;
            text-align: center;
            margin-bottom: 0px;
        }
    }
    :global(.MuiDialogActions-root) {
        display: flex;
        flex-direction: column;
        padding: 0px 30px 30px;
        p {
            font-size: 15px;
            line-height: 18px;
            font-weight: bold;
            color: $pelorous;
            margin-top: 28px;
            cursor: pointer;
        }
    }
    :global(.MuiButton-root) {
        color: $white;
        font-size: 15px;
        line-height: 18px;
        background-color: $pelorous;
        border: none;
        border-radius: 9px;
        padding: 11px;
        outline: none;
        cursor: pointer;
        text-transform: inherit;
        width: 100%;
        font-family: 'Cabin', sans-serif;
        &:hover {
            background-color: rgba($pelorous, 0.8);
        }
        :global(.MuiTouchRipple-root) {
            display: none;
        }
    }
}