@import '../../styles/base/colors.scss';

.sidebar_wrapper {
    width: 300px;
    height: 100%;
    position: absolute;
    left: 0;
    box-shadow: 0px 3px 6px rgba($black, 0.05);
    .sidebar_menu {
        margin-top: 20px;
    }
}

.sidebar_wrapper_mobile {
    .sidebar_menu {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid $malibu;
    }
}