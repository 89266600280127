@import '../../../styles/base/colors.scss';

.dashboard_wrapper_mobile,
.dashboard_wrapper {
    .sensors_wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    h2 {
        color: $prussian_blue;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;
        margin-bottom: 29px;
    }
}

.dashboard_wrapper_mobile {
    .sensors_wrapper {
        justify-content: space-around;
    }
}