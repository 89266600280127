@import '../../styles/base/colors.scss';

.text_field {
    :global(.MuiInputBase-root) {
        background-color: $alice_blue;
        border-radius: 9px;
        height: 40px;
        fieldset {
            border: none;
        }
        input {
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            line-height: 22px;
            color: $prussian_blue;
            border-radius: inherit;
            &::placeholder {
                color: $prussian_blue;
            }
            &:invalid {
                border: 1px solid $persian_red;
            }
        }
        :global(.MuiOutlinedInput-input) {
            padding: 6px 0 7px;
        }
    } 
    :global(.MuiFormHelperText-contained) {
        margin: 2px 0px 0px;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        font-family: 'Cabin', sans-serif;
    }
    :global(.MuiFormHelperText-root.Mui-error) {
        color: $persian_red;
    }
}