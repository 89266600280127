@import '../../styles/base/colors.scss';

.menu_wrapper {
    background-color: $alice_blue;
    z-index: 3;
    overflow: auto;
    .menu_header {
        display: flex;
        align-items: center;
        padding: 22px 20px;
        box-shadow: 0 9px 7px 0px $alice_blue;
        background-color: $white;
        p {
            margin: 0 auto;
            color: $prussian_blue;
            font-size: 22px;
            font-weight: bold;
        }        
    }
    .menu_items {
        margin-top: 20px;
        background-color: $white;
        box-shadow: 0 9px 7px 0px $alice_blue;
    }
    .menu_item {
        font-size: 22px;
        margin: 0px 15px;
        padding: 20px 0px;
        border-bottom: 1px solid $alice_blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-of-type {
            border-bottom: none;
        }
        p {
            color: $prussian_blue;
            &.active_item {
                font-weight: bold;
            }
        }
    }
    :global(.MuiSvgIcon-root) {
        color: $summer_sky;
        font-size: 36px;
    }
}