@import '../base/colors.scss';

.btn_default_rounded {
    color: $white;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    background-color: $pelorous;
    border: none;
    border-radius: 9px;
    padding: 11px;
    outline: none;
    cursor: pointer;
    width: 100%;
    
    &.disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
}