@import '../../styles/base/colors.scss';

.header_wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-shadow: 0px 3px 6px rgba($black, 0.05);
    font-size: 15px;
    line-height: 18px;

    .header_options {
        display: flex;
        align-items: center;
        ul {
            padding-left: 60px;
            display: flex;
            li {
                padding-right: 20px;
                &:last-of-type {
                    padding-right: 0px;
                }
            }
        }
    }

    a {
        color: $pelorous;
    }

    .button_rounded {
        background-color: $pelorous;
        border-radius: 100px;
        color: $white;
        padding: 8px 19px;
        border: none;
        margin-left: 39px;
    }

    .header_account {
        .header_user_section {
            display: flex;
        }
        .header_user_info {
            display: flex;
            flex-direction: column;
            p {
                color: $prussian_blue;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
            }
            a {
                text-align: right;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
            }
        }
        .header_icon_wrapper {
            width: 40px;
            height: 40px;
            box-shadow: 0px 3px 6px rgba($black, 0.16);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 11px;
        }
    }

    :global(.MuiSvgIcon-root) {
        color: $summer_sky;
        font-size: 36px;
    }
}
.active_item {
    font-weight: bold;
}