@import './colors.scss';

.icon_svg {
    mask-repeat: no-repeat !important;
    mask-position: center center !important;
    display: inline-block;
    position: relative;
    mask-size: contain;
    background-repeat: no-repeat;
}

.icon_logo {
    background-image: url('../../assets/icons/icon_logo.svg');
    min-width: 120px;
    min-height: 48px;
}

.icon_user_shield {
    mask-image: url('../../assets/icons/icon_user_shield.svg');
    width: 20px;
    height: 16px;
    background-color: $pelorous;
}

.icon_home {
    mask-image: url('../../assets/icons/icon_home.svg');
    width: 20px;
    height: 16px;
}

.icon_sensor {
    mask-image: url('../../assets/icons/icon_sensor.svg');
    width: 20px;
    height: 15px;
}

.icon_settings {
    mask-image: url('../../assets/icons/icon_settings.svg');
    width: 20px;
    height: 20px;
}

.icon_exclamation_circle {
    mask-image: url('../../assets/icons/icon_exclamation_circle.svg');
    width: 20px;
    height: 20px;
}

.icon_arrow {
    mask-image: url('../../assets/icons/icon_arrow.svg');
    width: 10px;
    height: 15px;
}