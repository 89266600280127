@import '../../../styles/base/colors.scss';

.sensor_wrapper {
    .sensor_cancel_subtitle {
        font-size: 15px;
        line-height: 18px;
        color: $pelorous;
        margin-bottom: 26px;
        cursor: pointer;
    }
    .cards_wrapper_payment_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $white;
        padding: 0px 30px;
        width: 300px;
        background: $white;
        border-radius: 15px;
        text-align: left;
    }
    h2 {
        color: $prussian_blue;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;
        margin-bottom: 29px;
    }
}