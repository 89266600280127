@import '../../../styles/base/colors.scss';

.account_details_wrapper {
    position: relative;
    h2 {
        color: $prussian_blue;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;
        margin-bottom: 29px;
    }
    .account_section_wrapper {
        display: flex;
        .account_user_details {
            background-color: $white;
            border-radius: 15px 0px 0px 15px;
            border-right: 3px solid $pattens_blue;
        }
        .account_payment_section {
            padding: 19px 0px;
            background-color: $white;
            border-radius: 0px 15px 15px 0px;
            .credit_card_error_msg {
                font-size: 12px;
                line-height: 15px;
                color: $persian_red;
                padding-left: 30px;
            }
        }
    }

    .link_section {
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        margin-bottom: 9px;
        div {
            color: $pelorous;
            font-size: 18px;
            line-height: 22px;
            cursor: pointer;
            &.bolded {
                margin-left: 20px;
                font-weight: bold;
            }
        }
        .hidden {
            display: none;
        }
    }

    // Mobile version only
    .account_mobile_section_wrapper {
        .account_user_details {
            border-right: 3px solid $pattens_blue;
        }
        .account_payment_section {
            margin-top: 20px;
            padding: 19px 0px;
            .credit_card_error_msg {
                font-size: 12px;
                line-height: 15px;
                color: $persian_red;
                padding-left: 30px;
            }
        }

        .account_user_details,
        .account_payment_section {
            background-color: $white;
            border-radius: 15px;
        }

        .button_mobile_section {
            margin: 0px 30px;
            button {
                &:first-of-type {
                    margin-bottom: 10px;
                }
                &.hidden {
                    display: none;
                }  
            }
        }
    }
}