@import '../../../styles/base/colors.scss';

.admin_layout_wrapper {
    padding: 20px;
    background-color: $pattens_blue;
    height: 100%;
    overflow: auto;
}

.admin_layout_wrapper_web {
    margin-left: 300px;
}